<template>
  <div class="live2d-container">
    <canvas id="liveCanvas" ref="liveCanvas"></canvas>
    <div v-if="showBubble" class="bubble" :style="bubbleStyle">
      {{ bubbleText }}
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  onBeforeUnmount,
  watch,
  reactive,
  computed,
  watchEffect,
} from "vue";
import * as PIXI from "pixi.js";
import { Live2DModel } from "pixi-live2d-display/cubism4";
import { Store } from "@/store/index";

window.PIXI = PIXI;
const store = Store();
const liveCanvas = ref(null);
const showBubble = ref(false);
const bubbleText = ref("");
const bubbleStyle = ref({});
const modelRef = ref(null);
let username = ref("");
let messageTimer = null; // 定时器变量
let hideBubbleTimer = null; // 气泡框消失定时器变量
const bx = ref(0);
const by = ref(0);
const messages = computed(() => {
  return [
    `${username.value},你好吖！`,
    "欢迎光临尚品记！",
    "今天过得怎么样？",
    "购买商品享受额外返利哦~",
    "成为博主，发布文章可获得返利！",
    "记得多多喝水哦！",
    "购买商品支持余额混合支付~",
    "要保持开心哦！",
    "商品滞销，帮帮我们/(ㄒoㄒ)/~~",
    "余额充值还享受额外赠送百分之十的福利~",
  ];
});

const showBubbleText = (text, x, y) => {
  bubbleText.value = text;
  bubbleStyle.value = {
    top: `${y - 30}px`,
    left: `${x + 10}px`,
  };
  showBubble.value = true;

  // 重置气泡框消失定时器
  clearTimeout(hideBubbleTimer);
  hideBubbleTimer = setTimeout(() => {
    showBubble.value = false;
  }, 3000);
};

const showRandomMessage = () => {
  const randomMessage =
    messages.value[Math.floor(Math.random() * messages.value.length)];
  if (modelRef.value && modelRef.value.position) {
    showBubbleText(
      randomMessage,
      liveCanvas.value.offsetLeft,
      liveCanvas.value.offsetTop
    );
  }
};

const resetMessageTimer = () => {
  clearInterval(messageTimer); // 清除现有定时器
  messageTimer = setInterval(showRandomMessage, 5000); // 重启定时器
};

onMounted(async () => {
  username.value = "亲爱的用户";
  watch(
    () => store.user,
    (newUser, oldUser) => {
      if (newUser && newUser.username) {
        username.value = newUser.username;
      }
    },
    { immediate: false } // 不立即执行回调，等待 user 变化
  );
  const app = new PIXI.Application({
    view: liveCanvas.value || undefined,
    autoStart: true,
    width: 200,
    height: 350,
    backgroundAlpha: 0,
  });
  // 以index.html开始，如果打包后不显示去掉一个../
  const model = await Live2DModel.from(
    "../../mao_pro_zh/runtime/mao_pro.model3.json"
  );
  modelRef.value = model;
  app.stage.addChild(model);
  model.scale.set(0.04);

  let dragging = false;
  let offsetX = 0;
  let offsetY = 0;
  // 鼠标触摸事件
  const onMouseDown = (e) => {
    dragging = true;
    const { x, y } = e;
    offsetX = liveCanvas.value.offsetLeft - x;
    offsetY = liveCanvas.value.offsetTop - y;
  };

  const onMouseMove = (e) => {
    if (dragging) {
      const { x, y } = e;
      liveCanvas.value.style.left = `${x + offsetX}px`;
      liveCanvas.value.style.top = `${y + offsetY}px`;
      bubbleStyle.value = {
        top: `${y + offsetY - 30}px`,
        left: `${x + offsetX + 10}px`,
      };
    }
  };

  const onMouseUp = () => {
    dragging = false;
  };
  // 平板触摸事件
  const onTouchDown = (e) => {
    dragging = true;
    const touch = e.touches[0]; // 获取第一个触摸点
    const { clientX, clientY } = touch;
    offsetX = liveCanvas.value.offsetLeft - clientX;
    offsetY = liveCanvas.value.offsetTop - clientY;
  };

  const onTouchMove = (e) => {
    if (dragging) {
      const touch = e.touches[0]; // 获取第一个触摸点
      const { clientX, clientY } = touch;
      liveCanvas.value.style.left = `${clientX + offsetX}px`;
      liveCanvas.value.style.top = `${clientY + offsetY}px`;
      bubbleStyle.value = {
        top: `${clientY + offsetY - 30}px`,
        left: `${clientX + offsetX + 10}px`,
      };
    }
  };

  const onTouchUp = () => {
    dragging = false;
  };

  // 在浏览器上
  liveCanvas.value.addEventListener("mousedown", onMouseDown);
  window.addEventListener("mousemove", onMouseMove);
  window.addEventListener("mouseup", onMouseUp);
  // 在平板上
  liveCanvas.value.addEventListener("touchstart", onTouchDown, false);
  window.addEventListener("touchmove", onTouchMove, false);
  window.addEventListener("touchend", onTouchUp, false);
  model.on("hit", (hitAreas) => {
    if (hitAreas.includes("body")) {
      model.motion("more");
      model.expression("exp_01");
      resetMessageTimer(); // 重置定时器
    } else if (hitAreas.includes("head")) {
      model.expression("exp_03");
      resetMessageTimer(); // 重置定时器
    }
  });
  model.on("mousedown", (e) => {
    const randomMessage =
      messages.value[Math.floor(Math.random() * messages.value.length)];
    showBubbleText(
      randomMessage,
      liveCanvas.value.offsetLeft,
      liveCanvas.value.offsetTop
    );
    resetMessageTimer(); // 重置定时器
  });
  model.on("touchstart", (e) => {
    const randomMessage =
      messages.value[Math.floor(Math.random() * messages.value.length)];
    showBubbleText(
      randomMessage,
      liveCanvas.value.offsetLeft,
      liveCanvas.value.offsetTop
    );
    resetMessageTimer(); // 重置定时器
  });

  messageTimer = setInterval(showRandomMessage, 5000); // 启动初始定时器
});
watchEffect(()=>{
  if(store.user.username) {
    username.value = store.user.username
  }else {
    username.value = "亲爱的用户"
  }
})
onBeforeUnmount(() => {
  clearInterval(messageTimer); // 清除定时器
  clearTimeout(hideBubbleTimer); // 清除气泡框消失定时器
  modelRef.value?.destroy();
  // 在浏览器上
  liveCanvas.value.removeEventListener("mousedown", onMouseDown);
  window.removeEventListener("mousemove", onMouseMove);
  window.removeEventListener("mouseup", onMouseUp);
  // 在平板上
  liveCanvas.value.removeEventListener("touchstart", onTouchDown, false);
  window.removeEventListener("touchmove", onTouchMove, false);
  window.removeEventListener("touchend", onTouchUp, false);
});
</script>

<style scoped>
.live2d-container {
  position: fixed;
  bottom: -20px;
  left: 20px;
  z-index: 850; /* 确保 container 在最上层 */
}

#liveCanvas {
  z-index: 850; /* 确保 canvas 在最上层 */
  position: relative; /* 确保 canvas 相对于其父元素移动 */
}

.bubble {
  position: absolute;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  white-space: nowrap;
  z-index: 1001;
}
</style>
