function timestampToTime(timestamp) {
  // 将时间戳从秒转换为毫秒
  timestamp = timestamp ? timestamp * 1000 : null;
  let date = new Date(timestamp);
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}
function timeToTimestamp(timeString) {  
  // 确保时间字符串的格式是正确的，这里只是一个简单的检查  
  const regex = /^(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2}):(\d{2})$/;  
  if (!regex.test(timeString)) {  
    throw new Error('Invalid time string format');  
  }  
  
  // 使用正则表达式提取年月日时分秒  
  const [, year, month, day, hours, minutes, seconds] = timeString.match(regex);  
  
  // 创建Date对象，注意月份是从0开始的，所以需要减1  
  const date = new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes), Number(seconds));  
  
  // 返回时间戳（秒）  
  return date.getTime()/1000;  
}  
function formatDate(dateStr) {
  // 将输入的日期字符串拆分为年、月、日
  const parts = dateStr.split('-');
  const year = parts[0];
  const month = parseInt(parts[1], 10); // 转换为整数并移除前导零
  const day = parseInt(parts[2], 10); // 同上

  // 构建并返回新的格式化日期字符串
  return `${year}年${month}月${day}日`;
}
function daysFromToday(timestamp) {
  // 创建给定时间戳的日期对象
  const targetDate = new Date(timestamp * 1000); // 将秒级时间戳转换为毫秒级
  // 创建今天的日期对象并将时间设置为午夜
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  // 计算时间差（以毫秒为单位）
  const timeDiff = today - targetDate ;
  // 将时间差转换为天数
  const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
  
  return Math.floor(daysDiff)+1; // 向下取整，得到整数天数
}
export {timestampToTime,formatDate,daysFromToday,timeToTimestamp}
