<template>
  <div
    id="app"
    @contextmenu.prevent="showContextMenu($event)"
    @click="hideContextMenu"
  >
    <router-view></router-view>
      <Live2DCharacter />
      <CustomContextMenu
        :visible="menuVisible"
        :position="menuPosition"
        @update:visible="menuVisible = $event"
      />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { Store } from "@/store/index";
import { getUserIp, autoLogin, getAllNotification,getChatRecords } from "@/request/api";
import { ElMessage } from "element-plus";
import Live2DCharacter from "@/components/Live2d/Live2DCharacter.vue";
import CustomContextMenu from "@/components/CustomContextMenu/CustomContextMenu.vue";
import { timestampToTime } from "@/util/time";
const store = Store();
const router = useRouter();
const menuVisible = ref(false);
const menuPosition = ref({ x: 0, y: 0 });

const showContextMenu = (event) => {
  event.preventDefault();
  menuPosition.value = { x: event.clientX, y: event.clientY };
  menuVisible.value = true;
};
const hideContextMenu = () => {
  if (menuVisible.value) {
    menuVisible.value = false;
  }
};

// 监听在线状态变化并更新 Pinia store 中的状态
window.addEventListener("online", () => {
  store.onLine = "online";
  ElMessage({
    type: "success",
    message: "网络重连成功",
  });
});

window.addEventListener("offline", () => {
  store.onLine = "offline";
  ElMessage({
    type: "error",
    message: "网络连接失败",
  });
});
// 获取用户聊天记录
const userGetChatRecords = () => {
  getChatRecords(store.user.uid).then((res)=>{
    if(res.payload) {
      store.chatRecords = res.payload
    }
    
  })
}
onMounted(() => {
  getUserIp().then((res) => {
    store.ipRegion = res.payload.ipRegion;
  });
  if (localStorage.getItem("token")) {
    const user = {
      avatarUrl: null,
      uid: null,
      phone: null,
      userType: 0,
      username: null,
      realAuth: false,
    };
    autoLogin().then((res) => {
      if (res.code === 200) {
        user.avatarUrl = res.payload.user.avatarUrl;
        user.uid = res.payload.user.id;
        user.phone = res.payload.user.phone;
        user.userType = res.payload.user.userType;
        user.username = res.payload.user.username;
        user.address = res.payload.user.address;
        user.realAuth = res.payload.realAuth;
        store.user = user;
        store.userInitiatedLogout = false; // 初始化用户主动退出为false
        store.connectWebSocket(user.uid);
        getAllNotification(res.payload.user.id).then((res) => {
          const notificationListTemp = res.payload;
          const convertedNotificationList = notificationListTemp.map(
            (notification) => {
              if (notification.notificationTime) {
                // 使用timestampToTime函数转换时间戳
                notification.notificationTime = timestampToTime(
                  notification.notificationTime
                );
              }
              return notification;
            }
          );

          store.notificationList = convertedNotificationList;
        });
        userGetChatRecords()
        if (
          router.currentRoute.value.path === "/login" ||
          router.currentRoute.value.path === "/register" ||
          router.currentRoute.value.path === "/"
        ) {
          router.push({
            name: "FindingView",
          });
        }
      }
    });
  }
  // 防止debounce报错
  const debounce = (fn, delay) => {
    let timer;
    return (...args) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
      callback = debounce(callback, 200);
      super(callback);
    }
  };
  // 网页禁用f12
  const preventDevTools = (event) => {
    if (
      event.key === "F12" ||
      (event.ctrlKey &&
        event.shiftKey &&
        (event.key === "I" || event.key === "C" || event.key === "J"))
    ) {
      event.preventDefault();
    }
  };

  window.addEventListener("keydown", preventDevTools);

  onUnmounted(() => {
    window.removeEventListener("keydown", preventDevTools);
  });
});
</script>

<style>
body {
  cursor: url("@/assets/images/pointer/normal.cur"),
    url("@/assets/images/pointer/normal.cur"), auto !important;
}
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
