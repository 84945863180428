import { defineStore } from "pinia";
import { ElMessage, ElMessageBox,ElNotification } from "element-plus";
import { timestampToTime } from "@/util/time";
export const Store = defineStore("Store", {
  state: () => ({
    onLine: "online",
    ipRegion: {},
    user: {},
    websocket: null, // 用于存储WebSocket连接
    heartbeatInterval: null, // 储存心跳机制
    userInitiatedLogout: false, // 用户是否主动注销websocket的属性
    payMessage: "",
    notificationList:[],
    chatRecords:{
      records:[]
    },
    sendForChatGPTFlag:true, // 能否向ChatGPT发送消息的标志
  }),
  actions: {
    clearAllUnRead() {
      this.notificationList.forEach(notification => {  
        notification.readState = 1; // 修改每个通知的 readState 为 1  
      });  
    },
    setWebSocket(websocket) {
      // 清除旧的心跳定时器
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }

      // 设置新的WebSocket连接
      this.websocket = websocket;

      // 如果websocket不为空，启动心跳机制
      if (websocket) {
        this.heartbeatInterval = setInterval(() => {
          // 检查WebSocket连接状态
          if (this.websocket.readyState === 1) {
            // WebSocket连接处于开启状态，发送心跳消息
            this.websocket.send("heartbeat");
          }
        }, 20000); // 每30秒发送一次心跳
      }
    },
    closeWebSocket() {
      // 清除心跳定时器
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }

      // 关闭WebSocket连接
      if (this.websocket) {
        this.websocket.close();
        this.websocket = null;
      }
    },
    connectWebSocket(id, attempt = 1) {
      // WebSocket的readyState的数字含义：
      // 0 (WebSocket.CONNECTING) 正在链接中
      // 1 (WebSocket.OPEN) 已经链接并且可以通讯
      // 2 (WebSocket.CLOSING) 连接正在关闭
      // 3 (WebSocket.CLOSED) 连接已关闭或者没有链接成功
      if (
        this.websocket &&
        (this.websocket.readyState === 1 || this.websocket.readyState === 0)
      ) {
        return;
      }
      // 关闭已存在的连接
      this.closeWebSocket();

      // 建立新的WebSocket连接
      // const ws = new WebSocket(`ws://localhost:8017/webSocket/${id}`);
      const ws = new WebSocket(`wss://api.shangpinji.shop/webSocket/${id}`);
       // https需要更改为wss
      // 连接打开时的事件处理
      ws.onopen = () => {
        // console.log("WebSocket连接成功");
        if (attempt > 1) {
          ElMessage({
            type: "success",
            message: "重连成功",
          });
        }
        attempt = 1;
      };
      ws.onmessage = (event) => {
        if (event.data === "kickout") {
          //   alert("您的账号在其他地方登录。");
          this.userInitiatedLogout = true // 强制踢出用户
          localStorage.clear();
          ElMessageBox.confirm("您的账号在其他地方登录。", "下线通知", {
            confirmButtonText: "确定",
            cancelButtonText: '取消',
            type: "error",
          })
            .then(() => {
              
              window.location.href = "/login";
              
            })
            .catch(() => {
              window.location.href = "/login";
            });
        } else {
          if (event.data != "heartbeat") {
            const message = JSON.parse(event.data);
            if (message.type === "pay") {
              // 处理后端传递过来的支付状态，支付失败或者支付成功
              this.payMessage = message.data;
            }
            if (message.type === "publishTask") {
              const getData = message.data;
              getData.notificationTime = timestampToTime(getData.notificationTime)     
              this.notificationList.unshift(getData);
              ElNotification({
                title: '通知',
                message: '收到一条新的上传通知',
                type: 'info',
              })
            }
            if(message.type === "chat") {
              this.handleIncomingChatMessage(message.data);
              this.sendForChatGPTFlag = true;
            }
            if (message.type === "lieutenantGeneral") {
              const getData = message.data;
              getData.notificationTime = timestampToTime(getData.notificationTime)     
              this.notificationList.unshift(getData);
              ElNotification({
                title: '通知',
                message: '收到一条新的中奖通知',
                type: 'info',
              })
            }
          }
        }
      };
      // 连接关闭时尝试重新连接，最多五次
      ws.onclose = () => {
        // console.log("WebSocket连接已关闭");

        if (this.userInitiatedLogout) {
          // console.log("用户主动退出，不尝试重连");
          return;
        }
        ElMessage({
          type: "error",
          message: "500 与网站断开连接",
        });
        if (attempt < 6) {
          setTimeout(() => {
            // console.log(`尝试重新连接第${attempt}次`);
            this.setWebSocket(null); // 清除当前的WebSocket引用
            //   const id = JSON.parse(localStorage.getItem("ui")).id;
            this.connectWebSocket(id, attempt + 1); // 递增尝试次数并重新连接
          }, 5000); // 等待5秒后重连，以便给服务器和网络状态一些恢复时间
        } else {
          // console.log("WebSocket连接失败超过五次，停止尝试重连");
          ElMessageBox.confirm(
            "网络连接问题，WebSocket连接失败。请检查您的网络状态或联系支持。",
            "连接失败",
            {
              confirmButtonText: "确定",
              cancelButtonText: '取消',
              type: "error",
            }
          )
            .then(() => {})
            .catch(() => {});
        }
      };
      ws.onerror = (error) => {
        // console.log("WebSocket连接发生错误", error);
        ws.close(); // 显式关闭可能处于异常状态的连接
      };

      // 保存WebSocket连接到Vuex
      this.setWebSocket(ws);
    },
    ensureWebSocketConnected() {
      // console.log(state.websocket.readyState)
      // 检查WebSocket是否已经连接
      if (!this.websocket || this.websocket.readyState !== 1) {
        // 尝试重新连接WebSocket，这里假设您已经保存了用户账号信息
        // 例如在state.user.id或通过其他方式
        const id = this.user.uid;
        if (id) {
          this.connectWebSocket(id, 1);
        }
      }
    },
    handleIncomingChatMessage(message) {
      const { senderId, receiverId, ...rest } = message;
      const userId = this.user.uid;
      const targetUserId = senderId === userId ? receiverId : senderId;

      let chatRecord = this.chatRecords.records.find(
        (record) => record.userId === targetUserId
      );

      if (chatRecord) {
        chatRecord.messageList.push({ senderId, receiverId, ...rest });
      } else {
        // 如果不存在聊天记录，创建新的记录
        chatRecord = {
          userId: targetUserId,
          username:message.senderUsername,
          avatarUrl:message.senderAvatarUrl,
          messageList: [{ senderId, receiverId, ...rest }],
        };
        this.chatRecords.records.push(chatRecord);
      }

      // 将该聊天记录数组放在第一个
      this.chatRecords.records = [
        chatRecord,
        ...this.chatRecords.records.filter(
          (record) => record.userId !== targetUserId
        ),
      ];
    },
    getChatRecordById(userId) {
      return this.chatRecords.records.find(record => record.userId == userId);
    },
    addChatRecord(newRecord) {
      this.chatRecords.records.unshift(newRecord);
    },
    moveChatRecordToFirst(userId) {
      const index = this.chatRecords.records.findIndex(record => record.userId === userId);
      if (index !== -1) {
        const [record] = this.chatRecords.records.splice(index, 1);
        this.chatRecords.records.unshift(record); // 将记录移动到数组的第一位
      }
    }
  },
});
