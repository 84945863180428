<template>
  <div
    v-if="visible"
    :style="menuStyle"
    class="context-menu"
    @click="hideMenu"
    
  >
    <ul>
      <li @click="refreshPage">页面刷新</li>
      <li @click="goToProfile">个人详情</li>
      <li @click="goToGift">兑换礼品</li>
      <li @click="goPublish">发布文章</li>
      <li @click="shareLink">分享链接</li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { Store } from '@/store/index';
import { ElMessage } from 'element-plus';

const store = Store();
const router = useRouter();
const props = defineProps({
  visible: Boolean,
  position: Object,
});

const emit = defineEmits(['update:visible']);

const hideMenu = () => {
  emit('update:visible', false);
};

const refreshPage = () => {
  window.location.reload();
};

const goToProfile = () => {
  router.push({
    name: 'PersonalView',
    params: {
      uid: store.user.uid
    }
  });
};

const goToGift = () => {
  router.push({
    name: 'PersonalView',
    params: {
      uid: store.user.uid
    }
  });
};

const goPublish = () => {
  router.push({
    name: 'PublishView',
  });
};
const shareLink = () => {
  navigator.clipboard.writeText(window.location.href).then(() => {
    ElMessage.success('链接已复制到剪贴板');
  }).catch(err => {
    ElMessage.error('无法复制链接: ', err);
  });
};

// 生成较为暗淡的随机颜色
const getRandomColor = () => {
  const base = 60; // 基础色值，避免生成过于鲜艳的颜色
  const r = Math.floor(Math.random() * (256 - base) + base);
  const g = Math.floor(Math.random() * (256 - base) + base);
  const b = Math.floor(Math.random() * (256 - base) + base);
  return `rgba(${r}, ${g}, ${b}, 1)`;
};

// 计算菜单样式
const menuStyle = computed(() => {
  const menuWidth = 200; // 菜单宽度
  const menuHeight = 150; // 菜单高度（估算）
  let top = props.position.y;
  let left = props.position.x;

  // 检查是否超出右边界
  if (left + menuWidth + 40 > window.innerWidth) {
    left = props.position.x - menuWidth - 40;
  }

  // 检查是否超出左边界
  if (left < 0) {
    left = 10; // 防止超出左边界
  }

  // 检查是否超出下边界
  if (top + menuHeight + 100 > window.innerHeight) {
    top = window.innerHeight - menuHeight - 100; // 留一点间隙
  }

  return {
    top: `${top}px`,
    left: `${left}px`,
    backgroundColor: getRandomColor(),
    width: '200px',
    padding: '10px',
    borderRadius: '8px',
    color: 'white',
    fontSize: '14px',
    position: 'absolute',
    zIndex: 1000,
  };
});

// 监听visible变化，每次显示菜单时更新背景颜色
watch(() => props.visible, (newVal) => {
  if (newVal) {
    menuStyle.value.backgroundColor = getRandomColor();
  }
});
</script>

<style scoped>
.context-menu {
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.context-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.context-menu li {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.context-menu li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
