import { get, post } from "./http";
import { ElMessage } from "element-plus";

// 用户操作接口
// 登录接口
export const loginByPassword = (data) => post("user/loginByPassword", data);
export const loginByCode = (data) => post("user/loginByCode", data);
export const register = (data) => post("user/register", data);
export const captcha = (type) => get(`/captcha?type=${type}`);
export const checkCaptcha = (data) => post("/checkCaptcha", data);
export const checkLoginTelCode = (phone,code) => post(`/user/checkLoginTelCode?phone=${phone}&code=${code}`)
export const checkRegisterTelCode = (phone,code) => post(`/user/checkRegisterTelCode?phone=${phone}&code=${code}`)
export const getLoginCode = (phone) => post(`user/getLoginCode?phone=${phone}`);
export const getRegisterCode = (phone) =>
  post(`user/getRegisterCode?phone=${phone}`);
export const getUserIp = () => post("user/getUserIp");
export const usernameOnlyCheck = (username) =>
  post(`user/usernameOnlyCheck?username=${username}`);
export const emailOnlyCheck = (email) =>
  post(`user/emailOnlyCheck?email=${email}`);
// 自动登录(不记录登录次数)
export const autoLogin = () => post("user/autoLogin");
// 获取文章(分类)
export const getNoteInfoByClassfy = (tag, page, userId) =>
  get(`note/getNoteInfoByClassfy?tag=${tag}&page=${page}&userId=${userId}`);
export const getNoteInfoBySearch = (searchValue, page, userId) =>
  get(
    `note/getNoteInfoBySearch?search=${searchValue}&page=${page}&userId=${userId}`
  );
// 获取文章(id)
export const userGetNoteInfoById = (nid, userId) =>
  get(`note/userGetNoteInfoById?nid=${nid}&userId=${userId}`);
// 点赞文章
export const notePraise = (nid, userId, praise) =>
  post(`note/notePraise?nid=${nid}&userId=${userId}&praise=${praise}`);
// 收藏文章
export const noteCollect = (nid, userId, collection) =>
  post(`note/noteCollect?nid=${nid}&userId=${userId}&collection=${collection}`);
// 发布文章评论
export const sendNoteComment = (data) => post("note/sendNoteComment", data);
// 发布文章
export const publishNote = (data) => post("note/publishNote", data);

// 发布文章，并监控上传进度
export const publishNoteWithProgress = (data, onUploadProgress) => {
  // 创建一个用于显示进度的 ElMessage 实例
  const messageInstance = ElMessage({
    message: '<div id="progress-container"><div id="progress-bar"></div></div>',
    dangerouslyUseHTMLString: true,
    duration: 0, 
  });
  const longTimeout = 10 * 60 * 1000; // 设置最大请求时间为10分钟
  return post("note/publishNote", data, {
    timeout: longTimeout,
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      const progressBar = document.getElementById("progress-bar");
      if (progressBar) {
        progressBar.style.width = percentCompleted + "%";
      }

      const progressContainer = document.getElementById("progress-container");
      if (progressContainer) {
        progressContainer.innerText = "上传进度：" + percentCompleted + "%";
      }
      // 检查进度是否达到100%  
    if (percentCompleted >= 100) {  
      // 关闭ElMessage  
      if (messageInstance) {  
        messageInstance.close();  
      }  
    }  
    },
  }).then(response => {
    // 上传完成，确保关闭ElMessage
    messageInstance.close();
    return response;
  })
  .catch(error => {
    // 上传失败，确保关闭ElMessage
    messageInstance.close();
    throw error;
  });;
};

// 个人中心模块
export const getUserInfoByIdAndOwn = (uid, owner) =>
  post(`user/getUserInfoByIdAndOwn?uid=${uid}&owner=${owner}`);
export const getNoteByUid = (tag, page, userId) =>
  get(`note/getNoteByUid?tag=${tag}&page=${page}&userId=${userId}`);
export const updateUserInfo = (user) => post("user/updateInfo", user);
export const updatePassword = (user) => post("user/updatePassword", user);
export const realnameAuth = (realAuth) =>
  post("user/userRealnameAuth", realAuth);

// 通知模块
export const getAllNotification = (userId) => get(`notification/userGetNotification?userId=${userId}`)
// 修改已读
export const updateReadState = (id) => post(`notification/updateReadState?id=${id}`)
// 清空未读
export const clearUnRead = (userId) => post(`notification/clearUnRead?userId=${userId}`)

// 支付模块
export const payOrder = (order) => post("pay/multiplePay", { orderVo: order });

// 商品模块
export const getAllGoodsInfo = () => get("goods/getAllGoods");
export const getGoodsInfoByClassfy = (tag, page, userId) =>
  get(`goods/getGoodsInfoByClassfy?tag=${tag}&page=${page}&userId=${userId}`);
// 获取商品(id)
export const userGetGoodsInfoById = (gid, userId) =>
  get(`goods/userGetGoodsInfoById?gid=${gid}&userId=${userId}`);
// 发布商品评论
export const sendGoodsComment = (data) => post("goods/sendGoodsComment", data);

// 购物车模块
export const getUserCart = (uid) => get(`cart/selectUserCart?uid=${uid}`);
export const addCart = (goods) => post("cart/addGoodsToCart", goods);
export const updateShoppingCartCount = (count, amount, goodsId, userId) =>
  post(
    `cart/updateShoppingCartCount?count=${count}&amount=${amount}&goodsId=${goodsId}&userId=${userId}`
  );
export const deleteShoppingCart = (goodsId, userId) =>
  post(`cart/deleteShoppingCart?goodsId=${goodsId}&userId=${userId}`);
// 订单详情模块
export const getUserAllOrders = (uid) => get(`orders/getAllOrders?uid=${uid}`);
export const userReceivingGoods = (oid) =>
  post(`orders/receivingGoods?oid=${oid}`);
export const userRefundOrder = data => post('orders/userRefundOrder',data)
// 兑换码模块
export const userExchangeCode = (redemptionCode) =>
  post("exchange/userExchangeCode", redemptionCode);

// 聊天模块
export const getChatRecords = (uid) => get(`chat/getChatRecords?id=${uid}`)
export const sendChatMessageForUser = (chatRecordMessageVo) => post("chat/sendChatMessageForUser",chatRecordMessageVo)
export const sendChatMessageForChatGPT = (chatRecordMessageVo) => post("chat/sendChatMessageForChatGPT",chatRecordMessageVo)
export const clearChatUnRead = (userId,otherUserId) => post(`chat/clearChatUnRead?userId=${userId}&otherUserId=${otherUserId}`)
export const checkUserBalanceForChatGPT = (userId) => post(`chat/checkUserBalanceForChatGPT?uid=${userId}`)
export const getRecommendFriend = (uid) => get(`chat/getRecommendFriend?uid=${uid}`)
export const getChatGPTInfo = () => get("user/getChatGPTInfo")
export const getCustomerInfo = () => get("user/getCustomerInfo")


export const addUserHobbyInfo = (data) => post("user/addUserHobbyInfo",data)