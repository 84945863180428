import axios from "axios";
import { networkCheck } from "@/util/network";
import { router } from "@/router";
import { ElMessage } from "element-plus"; // 直接导入Message


// axios.defaults.baseURL = 'http://localhost:8017/'
axios.defaults.baseURL = "https://api.shangpinji.shop/";

axios.defaults.timeout = 200000; // 设置为200s 由于要获取图片和视频太多所以放大一点请求时间
// axios.defaults.headers.post['Content-Type'] = 'application/json';
 
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = localStorage.getItem("token");
    token && (config.headers["token"] = token);
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      // 默认JSON类型，可以根据实际情况设置
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    let res = response.data;
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (res.code === 200) {
      return Promise.resolve(response);
    } else if (res.code === 401) {
      if (localStorage.getItem("token") === null) {
        ElMessage({
          type: "error",
          message: "请先进行登录！",
        });
      } else {
        ElMessage({
          type: "error",
          message: "身份信息有误，请重新登录！",
        });
        localStorage.clear();
      }
      router.push({
        name: "LoginView",
      });
      return Promise.resolve(response);
    } else if (res.code == 400) {
      // 接口网络问题
      ElMessage({
        type: "error",
        message: "服务异常，请稍后重试",
      });
      return Promise.resolve(response);
    } else if (res.code == 403) {
      // 请求被拒绝
      router.push({
        name: "LoginView",
      });
      ElMessage({
        type: "error",
        message: res.msg,
      });
      return Promise.resolve(response);
    } else if (res.code == 429) {
      // ip被冻结 提示429
      router.push({
        name: "LoginView",
      });
      ElMessage({
        type: "error",
        message: res.msg,
      });
      return Promise.resolve(response);
    } else if (res.code == 500) {
      ElMessage({
        type: "error",
        message: res.msg,
      });
      return Promise.resolve(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  (error) => {
    // 捕获错误并使用Element UI的Message组件显示错误信息
    if (error.response) {
      // 服务器返回了响应，你可以根据error.response的状态码来决定错误消息
      ElMessage({
        type: "error",
        message: "禁止访问！", // 这里可以根据实际错误来定制消息
      });
    } else if (error.request) {
      // 请求已经发出去了，但是没有收到响应
      ElMessage({
        type: "error",
        message: "网络错误，请检查您的网络连接！", // 自定义网络错误消息
      });
    } else {
      // 发生了其他错误
      ElMessage({
        type: "error",
        message: error.message || "未知错误", // 自定义或使用axios提供的错误消息
      });
    }

    // 阻止错误继续冒泡，这里可以根据需要返回Promise.reject或Promise.resolve
    return Promise.reject(error); // 如果你希望在之后还能捕获到这个错误，则使用Promise.reject
    // return Promise.resolve(); // 如果你不希望在之后捕获到这个错误，可以使用Promise.resolve
  }
);
// params: {
//   data: data
// } GET的传参方法
export function get(url, data) {
  if (!networkCheck()) {
    // 判断网络连接
    return Promise.reject(new Error("无网络连接"));
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function post(url, data, config = {}) {
  if (!networkCheck()) {
    // 判断网络连接
    return Promise.reject(new Error("无网络连接"));
  } else {
    return new Promise((resolve, reject) => {
      // 合并默认配置和传入的配置
      const defaultConfig = {
        // 可以在这里添加其他默认配置
      };
      const finalConfig = { ...defaultConfig, ...config };
      axios
        .post(url, data,finalConfig)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
