import {Store} from "@/store/index";
// 时间戳转换为时间
export function networkCheck() {
  const store = Store(); 
  const networkState = store.onLine;
  if (networkState === 'online') {
    return true;
  } else if (networkState === 'offline') {
    return false
  }
  
}