// 该文件专门用于创建整个应用的路由器
import { createRouter, createWebHistory } from 'vue-router';
import { ElMessage } from 'element-plus' 




const router = createRouter({
  history: createWebHistory(), // history则路由的/#就没了
  routes:[
    {   
      path: '/',
      redirect: '/finding'
    },
    {
      path: '/login',
      name: 'LoginView',
      component: () => import('@/views/user/LoginView.vue'),
      props: true,
      meta: {
        title: '登录',
      }
    },
    {
      path: '/register',
      name: 'RegisterView',
      component: () => import('@/views/user/RegisterView.vue'),
      props: true,
      meta: {
        title: '注册',
      }
    },
    {
      path:'/explore',
      name:'ExploreView',
      component:()=> import('@/views/explore/ExploreView.vue'),
      props: true,
      meta: {
        title: '首页',
      },
      children:[
        {
          path:'/finding/:nid?',
          name:'FindingView',
          component:() => import('@/views/explore/finding/FindingView.vue'),
          props:true,
          
        },
        {
          path:'/publish',
          name:'PublishView',
          component:() => import('@/views/explore/publish/PublishView.vue'),
          props:true,
          
        },
        {
          path:'/personal/:uid?',
          name:'PersonalView',
          component:()=>import('@/views/user/personal/PersonalView.vue'),
          props:true,
        },
        {
          path:'/goods/:gid?',
          name:'GoodsView',
          component:() => import('@/views/explore/goods/GoodsView.vue'),
          props:true,
          
        },
        {
          path:'/cart',
          name:'CartView',
          component:() => import('@/views/explore/goods/CartView.vue'),
          props:true,
          
        },
        {
          path:'/order',
          name:'OrderView',
          component:() => import('@/views/explore/goods/OrderView.vue'),
          props:true,
          
        },
        {
          path:'/chat',
          name:'ChatView',
          component:() => import('@/views/chat/ChatView.vue'),
          props:true,
          
        },
      ]
    }
  ]
})
// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  // 检查即将前往的路由是否非登录页面
  if (to.path !== '/login' && to.path !== '/register') {
    // 检查用户是否已登录
    if (localStorage.getItem('token') === null) {
      // 未登录，则重定向到登录页面
      next('/login');
    }
     else {
      next();
    }
  } else {
    // 如果目标是登录页面，直接前往
    next();
  }
});
// 解决路由重复导致的报错
// const routerPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch((error) => error);
// };
export {router};